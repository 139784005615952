<template>
    <ODataLookup ref="dataLookup" :dataObject="dsPersonsLkp" :whereClause="whereClause" reloadOnWhereClauseChange
        :disableRecent="noRecent" :title="$t('Person')" v-bind="$attrs">
        <template #toolbarActions>
            <slot name="toolbarActions"></slot>
        </template>
        <template #target="scope">
            <slot v-if="$slots.target" name="target" v-bind="scope"></slot>
            <span v-else-if="$slots.person" :ref="scope.target" style="cursor: pointer;">
                <slot name="person"></slot>
            </span>
        </template>
        <OColumn field="Name" width="200" />
        <OColumn field="MobileNo" width="150" />
        <OColumn field="Email" width="200" />
        <OColumn field="Represents" width="150" />
    </ODataLookup>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { ODataLookup } from 'o365-datalookup';
const dataLookup = ref(null);

const props = defineProps<{
    dataObject?: any,
    noRecent?: boolean,
    whereClause?: string,
}>();
function close() {
    if (dataLookup.value?.close) {
        dataLookup.value.close();
    }
}
const dsPersonsLkp = props.dataObject ?? $getDataObjectById('dsSystem_PersonsLookup');
defineExpose({ close });

</script>
